import { AxiosResponse, CancelToken } from 'axios'
import { IApiResource, IBasePaginationParameters, IPaginationApiResponse } from '../../types'
import { ICartMode, ICartValidationMode } from '../carts/types'
import { IManagerSalesman } from '../salesman/types'

export enum ICustomerType {
    // compte standard (flag pour valider ou non une commande)
    Simple = 'simple',
    // compte multi magasin
    MultiStore = 'multi_store',
}

export interface ICustomerModifyLoginParameters {
    old_password: string
    new_password: string
}

export enum ICustomerMinimumAmountMode {
    Strict = 'strict',
    Permissive = 'permissive',
    None = 'none',
}

export interface ICustomerIdentity extends IApiResource {
    readonly id: string
    readonly business_name: string
    readonly code: string
    // sérialisé que dans certains cas côté api (détail commande actuellement)
    readonly store_code?: string
}

export interface ICustomer extends ICustomerIdentity {
    readonly account_type: ICustomerType
    readonly address1: string | null
    readonly address2: string | null
    readonly postcode: string
    readonly city: string
    readonly country_name: string | null
    readonly email: string
    readonly telephone?: string
    readonly internal?: boolean
    readonly order_available: boolean
    readonly allow_access_invoices: boolean
    readonly address_code: string
    readonly stores?: Array<ICustomer>
    readonly general_price: boolean
    readonly holidays: Array<string>
    readonly store_code?: string
    readonly buying_group_badge?: string
    readonly minimum_amount_mode: ICustomerMinimumAmountMode
    readonly can_place_order: boolean
    readonly alert_cart_in_progress?: Array<string>
    readonly can_order_on_main_store?: boolean // uniquement présent sur un compte multiStore
    readonly show_container_volume?: boolean
    readonly has_virtual_cart?: boolean
    readonly allow_picture_export?: boolean
    readonly allow_declare_anomaly?: boolean
    readonly cart_mode: ICartMode
    readonly cart_validation_mode: ICartValidationMode
    readonly prospect: boolean
    readonly hide_prices?: boolean
    readonly lock_cart_active?: boolean
    readonly has_cart_in_progress?: boolean
    readonly has_late_payment?: boolean
    readonly order_by_unit?: boolean
    readonly allow_remainders?: boolean
    readonly salesmen?: Array<IManagerSalesman>
    readonly prices_with_eco_tax?: boolean
}

export interface ISalesmanCustomerList
    extends Pick<
        ICustomer,
        | '@type'
        | '@id'
        | 'id'
        | 'business_name'
        | 'account_type'
        | 'address1'
        | 'address2'
        | 'postcode'
        | 'city'
        | 'country_name'
        | 'email'
        | 'telephone'
        | 'code'
        | 'has_late_payment'
        | 'has_cart_in_progress'
        | 'salesmen'
    > {
    readonly store_code: string
}

export type IApiCustomerListCollection = Array<ISalesmanCustomerList>
export type IApiCustomerListParameters<D = ISalesmanCustomerList> = IBasePaginationParameters &
    Partial<Record<keyof D, string>> & {
        all?: 1 | 0
        prospect: 1 | 0
    }
export type IApiCustomerListPagination<T = IApiCustomerListCollection> = IPaginationApiResponse<T>
export type IApiCustomerListResponse<T = IApiCustomerListCollection> = AxiosResponse<IApiCustomerListPagination<T>>
export type IApiCustomerGetResponse = AxiosResponse<ICustomer>

export interface ICustomersService {
    get(customerId: string): Promise<IApiCustomerGetResponse>
    list<T = IApiCustomerListCollection>(
        params: IApiCustomerListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiCustomerListResponse<T>>
    changePassword(params: ICustomerModifyLoginParameters): Promise<AxiosResponse>
}
