/**
 *
 * AppLoader
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { memo, ReactNode, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Logo from '../Logo/Logo'
import isUndefined from 'lodash/isUndefined'

interface IProps {
    animated: boolean
    show: boolean
    showLabel: boolean
    showLogo: boolean
    label?: string
    children?: ReactNode
}

function PartLoader({ animated, show, showLabel, showLogo, label, children }: IProps): JSX.Element {
    useEffect(() => {
        if (show) {
            document.documentElement.classList.add('part-loading')
        } else {
            document.documentElement.classList.remove('part-loading')
        }

        return () => {
            document.documentElement.classList.remove('part-loading')
        }
    }, [show])

    return (
        <div
            className={classNames('loader-part', {
                'animate__animated animate__fadeIn': animated && show,
                'd-none': !animated && !show,
                'animate__animated animate__fadeOut': animated && !show,
            })}
        >
            {showLogo && <Logo />}
            <FontAwesomeIcon icon="circle-notch" spin={true} />
            {showLabel && (
                <h5 className={'title'}>
                    {label ? <span>{label}</span> : <FormattedMessage id={'default.loading'} />}
                </h5>
            )}
            {!isUndefined(children) ? <div className="details">{children}</div> : <></>}
        </div>
    )
}

PartLoader.defaultProps = {
    animated: true,
    show: true,
    showLabel: true,
    showLogo: false,
} as Partial<IProps>
export default memo(PartLoader)
