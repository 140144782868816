/**
 *
 * CustomerInfo
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent } from 'react'
import CustomerStock from './CustomerInfo/CustomerStock'
import CustomerLastOrder from './CustomerInfo/CustomerLastOrder'
import InProgressOrders from './CustomerInfo/InProgressOrders'
import { OrderStates, ProductOrderInProgressCollection } from '../../../services/api/service/orders/types'
import InProgressOrderQuantity from './CustomerInfo/InProgressOrderQuantity'
import ArrivalInfo from './CustomerInfo/ArrivalInfo'
import { useCustomer } from '../../../utils/hook/useCustomer'

interface IProps {
    uniqId?: string
    customerStock: number | null
    customerStockDate: string | null
    customerLastOrderDate: string | null
    customerLastOrderQuantity: number | null
    arrivalStockDate: string | null
    ordersInProgress?: ProductOrderInProgressCollection
    orderInProgressQuantity?: number
    tooltip?: boolean
    parts?: Array<CustomerInfoPart>
    customerLastOrderState: OrderStates | null
    onCustomerStockClick?: (e: MouseEvent) => void
    onCustomerOrderClick?: (e: MouseEvent) => void
    onCustomerInProgressOrderClick?: (e: MouseEvent, orderId?: string) => void
}

export enum CustomerInfoPart {
    Stock = 'stock',
    Arrival = 'arrival',
    LastOrder = 'last_order',
    InProgressOrders = 'in_progress_orders',
    InProgressOrderQuantity = 'in_progress_order_quantity',
}

function CustomerInfo({
    tooltip = true,
    customerStock = null,
    customerStockDate = null,
    arrivalStockDate = null,
    onCustomerStockClick,
    customerLastOrderDate,
    customerLastOrderQuantity,
    customerLastOrderState,
    onCustomerOrderClick,
    ordersInProgress,
    orderInProgressQuantity,
    onCustomerInProgressOrderClick,
    parts = [],
    uniqId,
}: IProps): JSX.Element {
    const { isShowProductCustomerInfoAllowed } = useCustomer()

    if (typeof parts === 'undefined' || parts.length === 0) {
        return <></>
    }

    return (
        <div className={classNames('product-member-information')}>
            <div className={'content'}>
                {parts?.indexOf(CustomerInfoPart.Arrival) > -1 && <ArrivalInfo arrivalDate={arrivalStockDate} />}
                {parts?.indexOf(CustomerInfoPart.Stock) > -1 && isShowProductCustomerInfoAllowed && (
                    <CustomerStock
                        customerStock={customerStock}
                        customerStockDate={customerStockDate}
                        onClick={onCustomerStockClick}
                    />
                )}
                {parts?.indexOf(CustomerInfoPart.LastOrder) > -1 && (
                    <CustomerLastOrder
                        uniqId={uniqId}
                        tooltip={tooltip}
                        lastOrderQuantity={customerLastOrderQuantity}
                        lastOrderDate={customerLastOrderDate}
                        lastOrderState={customerLastOrderState}
                        onClick={onCustomerOrderClick}
                    />
                )}
                {parts?.indexOf(CustomerInfoPart.InProgressOrders) > -1 && ordersInProgress && (
                    <InProgressOrders orders={ordersInProgress} onClick={onCustomerInProgressOrderClick} />
                )}
                {parts?.indexOf(CustomerInfoPart.InProgressOrderQuantity) > -1 && (
                    <InProgressOrderQuantity quantity={orderInProgressQuantity} />
                )}
            </div>
        </div>
    )
}

CustomerInfo.defaultProps = {
    customerStock: null,
    customerStockDate: null,
} as Partial<IProps>

export default memo(CustomerInfo)
