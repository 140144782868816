/*
 *
 * OrderDetail
 *
 */

import classNames from 'classnames'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Card, Col, FormCheck, Overlay, Popover, Row, Table } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Button from '../../components/Buttons/Button'
import ShippingLocation from '../../components/Classification/ShippingLocation'
import ResumeCard from '../../components/Customer/ResumeCard'
import FlatIcon from '../../components/Icon/FlatIcon'
import OrderState from '../../components/Order/OrderState'
import OrderStepper from '../../components/Order/OrderStepper'
import Price from '../../components/Price/Price'
import {
    Heading as ProductHeading,
    MainPicture as ProductMainPicture,
    Packing as ProductPacking,
} from '../../components/Product/Partial'
import { CustomerLayoutRowTopId } from '../../pages/Layout/CustomerLayout'
import { ICustomer } from '../../services/api/service/customers/types'
import { IOrderDetail, IOrderDocumentType, IOrderType, OrderStates } from '../../services/api/service/orders/types'
import { IBaseProduct } from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'

import { canDeclareAnomaly, canReOrder } from '../../store/carts/utils'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import { orderDetailProcessAction } from '../../store/order/actions'
import {
    makeSelectOrderDetail,
    makeSelectOrderDetailError,
    makeSelectOrderDetailFetching,
} from '../../store/order/selectors'
import { generateProductUrl } from '../../utils/productHelper'
import { default as OrderDetailPlaceholder } from './Placeholder'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { canExportPictures } from '../../store/pictureExport/utils'
import pictureAction from '../../services/product/picker/type/picture'
import { isSalesmanResource } from '../../store/salesmens/utils'
import isNumber from 'lodash/isNumber'
import PictureExportModal from '../PictureExport/Loadable'
import PickerButton from '../../components/Products/Content/PickerButton'
import ReOrderButton from './Action/ReOrder'
import OrderAnomalyButton from './Action/OrderAnomaly'
import DocumentButton from './Action/Document'
import ExportButton from './Action/Export'
import InvoiceList from '../CustomerInvoices/Partial/InvoiceList'
import { makeSelectOrderCustomerWithId } from '../../store/orders/selectors'
import { makeSelectCartMode } from '../../store/carts/selectors'
import { StrictCartMode } from '../../store/carts/types'
import ContainerVolumes from '../../components/ContainerVolume/ContainerVolumes'
import { useCustomer } from '../../utils/hook/useCustomer'
import { customerCanExportOrderInExcel } from '../../store/customers/utils'

interface IProps {
    orderId: string
    orderType?: IOrderType
}

const stateSelector = createStructuredSelector<any, any>({
    detailFetching: makeSelectOrderDetailFetching(),
    detailError: makeSelectOrderDetailError(),
    orderDetail: makeSelectOrderDetail(),
    me: makeSelectAuthMe(),
    customer: makeSelectCustomer(),
    currentStore: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
})

function OrderDetail({ orderId, orderType = IOrderType.Order }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage, locale } = useIntl()
    const history = useHistory()
    const location = useLocation()
    const { isNeedPriceIncludingTaxes } = useCustomer()

    const { orderDetail, detailError, detailFetching, currentStore, customer, me, cartMode } = useSelector<
        IApplicationRootState,
        {
            orderDetail: IOrderDetail
            detailError?: IAppErrorTypes | undefined
            detailFetching: boolean
            me: IMe
            customer?: ICustomer
            currentStore?: ICustomer
            cartMode?: StrictCartMode
        }
    >(stateSelector)

    const selectOrderCustomer = useMemo(makeSelectOrderCustomerWithId, [])
    const customerOrder: ICustomer | undefined = useSelector<IApplicationRootState, ICustomer | undefined>((state) =>
        orderDetail ? selectOrderCustomer(state, orderDetail.customer.id) : undefined
    )

    const [currentOrderId, setCurrentOrderId] = useState<string>(orderId)
    const totalItems = orderDetail ? orderDetail.items.length : 0
    const isOrderAssetType = useMemo(() => orderType === IOrderType.Asset, [orderType])

    useEffect(() => {
        if ((!detailFetching && !detailError && !orderDetail) || (orderDetail && currentOrderId !== orderDetail.id)) {
            dispatch(orderDetailProcessAction(currentOrderId))
        }
    }, [detailFetching, detailError, currentOrderId, orderDetail, dispatch])

    useEffect(() => {
        setCurrentOrderId(orderId)
    }, [orderId, setCurrentOrderId])

    const isSalesman = useMemo(() => {
        return me && isSalesmanResource(me)
    }, [me])

    const allowedDeclareAnomaly = useMemo(() => {
        return orderDetail && canDeclareAnomaly(orderDetail, me)
    }, [me, orderDetail])

    const showPacking = useMemo(() => {
        return orderDetail && orderDetail.order_by_unit === false
    }, [orderDetail])

    const allowedReOrder = useMemo(() => {
        return orderDetail && (isSalesman || (customerOrder && canReOrder(customerOrder, currentStore, cartMode)))
    }, [customerOrder, currentStore, orderDetail, isSalesman, cartMode])

    const allowedExportInExcel = useMemo(() => {
        return customerOrder && customerCanExportOrderInExcel(customerOrder)
    }, [customerOrder])

    const handleOrderAnomalyModalDone = useCallback(() => {
        dispatch(orderDetailProcessAction(orderId))
    }, [dispatch, orderId])

    const onProductImageClick = (product: IBaseProduct) => {
        if (isSalesman && !customer) {
            return
        }

        const finalUrl = generateProductUrl(product.id, locale)
        history.push(finalUrl, { background: location })
    }

    const Component = document.getElementById(CustomerLayoutRowTopId) ? (
        ReactDOM.createPortal(
            <>
                <Col xs={24} md={'auto'}>
                    <Button variant={'link'} onClick={() => history.goBack()} className={'btn-back'}>
                        <FlatIcon icon={'chevron-left'} />
                        <FormattedMessage id={'default.back_to_list'} />
                    </Button>
                </Col>
            </>,
            document.getElementById(CustomerLayoutRowTopId)!
        )
    ) : (
        <></>
    )

    // selection
    const allowedExportPictures = useMemo(() => {
        return canExportPictures(me)
    }, [me])

    const pickerSelectAllInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const [activeProductPicker] = useState<boolean>(allowedExportPictures)
    const [selectedPickerProductIds, setSelectedPickerProductIds] = useState<Array<string>>([])
    const pictureExportActionRef = useRef() as React.MutableRefObject<HTMLButtonElement>
    const [showPictureExportTooltip, setShowPictureExportTooltip] = useState<boolean>(false)
    const [showPictureExportModal, setShowPictureExportModal] = useState<boolean>(false)
    const [pictureExportWarnings, setPictureExportWarnings] = useState<Array<string>>([])

    // on regarde s'il peux exporter les images de la commande
    useEffect(() => {
        const warns: Array<string> = []
        if (isNumber(pictureAction.options.min) && selectedPickerProductIds.length < pictureAction.options.min) {
            warns.push(formatMessage({ id: 'product_picker.min_warning' }, { min: pictureAction.options.min }))
        } else if (isNumber(pictureAction.options.max) && selectedPickerProductIds.length > pictureAction.options.max) {
            warns.push(formatMessage({ id: 'product_picker.max_warning' }, { max: pictureAction.options.max }))
        }
        setPictureExportWarnings(warns)
    }, [selectedPickerProductIds, setPictureExportWarnings, formatMessage])

    useEffect(() => {
        if (!allowedExportPictures || !pickerSelectAllInputRef.current) {
            return
        }
        pickerSelectAllInputRef.current.checked = selectedPickerProductIds.length === orderDetail?.items.length
        pickerSelectAllInputRef.current.indeterminate =
            selectedPickerProductIds.length > 0 && selectedPickerProductIds.length !== orderDetail?.items.length
    }, [orderDetail?.items, selectedPickerProductIds, allowedExportPictures])

    const handlePickerActivationAsked = useCallback(() => {
        setShowPictureExportModal(true)
    }, [setShowPictureExportModal])

    const handlePictureExportActionsEnter = useCallback(() => {
        if (pictureExportWarnings.length === 0) {
            return
        }

        setShowPictureExportTooltip(true)
    }, [setShowPictureExportTooltip, pictureExportWarnings])

    const handlePictureExportActionsLeave = useCallback(() => {
        setShowPictureExportTooltip(false)
    }, [setShowPictureExportTooltip])

    const handlePickerSelectAllChange = useCallback(() => {
        if (selectedPickerProductIds.length === orderDetail?.items.length) {
            setSelectedPickerProductIds([])
        } else {
            setSelectedPickerProductIds(orderDetail.items.map((item) => item.product['@id']))
        }
    }, [setSelectedPickerProductIds, selectedPickerProductIds, orderDetail])

    const handleProductPickerInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            // get value
            const currentTargetValue = e.target.value
            const currentTargetChecked = e.target.checked

            setSelectedPickerProductIds((ste) => {
                if (currentTargetChecked) {
                    return [...ste, currentTargetValue]
                }
                return ste.filter((st) => st !== currentTargetValue)
            })
        },
        [setSelectedPickerProductIds]
    )

    const handlePictureExportHide = useCallback(() => {
        setShowPictureExportModal(false)
    }, [setShowPictureExportModal])

    const handlePictureExportDone = useCallback(() => {
        setShowPictureExportModal(false)
        setSelectedPickerProductIds([])
    }, [setShowPictureExportModal, setSelectedPickerProductIds])

    return (
        <>
            <Helmet>
                <body className="customer-order-page" />
                <title>
                    {orderDetail
                        ? `${orderDetail.number} - ${formatMessage({ id: 'customer.orders' })}`
                        : `${formatMessage({ id: 'seo.order.title' })} - ${formatMessage({ id: 'customer.orders' })}`}
                </title>
            </Helmet>
            {Component}
            <ReactPlaceholder ready={typeof orderDetail !== 'undefined'} customPlaceholder={<OrderDetailPlaceholder />}>
                {detailError && <Alert variant="danger">{detailError.message}</Alert>}
                {orderDetail && (
                    <div className={'order-detail'}>
                        <Row className="row-order-detail-top">
                            {!isOrderAssetType && orderDetail.state !== OrderStates.Closed && (
                                <Col xs={24}>
                                    <OrderStepper state={orderDetail.state} />
                                </Col>
                            )}
                            <Col>
                                <Card className={classNames('member-card', 'resume')}>
                                    <Card.Header>
                                        <FormattedMessage id={`${orderDetail.type}.detail.title_number`}>
                                            {(txt) => (
                                                <h3 className={'hv'}>
                                                    {txt} <span className={'order-code'}>{orderDetail.number}</span>
                                                </h3>
                                            )}
                                        </FormattedMessage>
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped hover responsive>
                                            <tbody>
                                                {orderDetail.shipping_location && (
                                                    <tr>
                                                        <th>
                                                            <FormattedMessage id={'classification.transporter'} />
                                                        </th>
                                                        <td>
                                                            <ShippingLocation
                                                                shippingLocation={orderDetail.shipping_location}
                                                            />
                                                        </td>
                                                    </tr>
                                                )}
                                                {customerOrder && (
                                                    <tr>
                                                        <th>
                                                            <FormattedMessage
                                                                id={
                                                                    typeof customer === 'undefined' ||
                                                                    customer.id === customerOrder.id
                                                                        ? 'default.customer'
                                                                        : 'default.store'
                                                                }
                                                            />
                                                        </th>
                                                        <td>
                                                            <ResumeCard
                                                                customer={customerOrder}
                                                                showStoreCode={isSalesmanResource(me)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id={'orders.date'} />
                                                    </th>
                                                    <td>
                                                        <FormattedDate
                                                            value={orderDetail.date}
                                                            month={'long'}
                                                            day={'numeric'}
                                                            year={'numeric'}
                                                        />
                                                    </td>
                                                </tr>
                                                {!isOrderAssetType && (
                                                    <tr>
                                                        <th>
                                                            <FormattedMessage id={'orders.state'} />
                                                        </th>
                                                        <td>
                                                            <OrderState state={orderDetail.state} />
                                                        </td>
                                                    </tr>
                                                )}
                                                {!isOrderAssetType && orderDetail.state !== OrderStates.Closed && (
                                                    <tr>
                                                        <th>
                                                            <FormattedMessage
                                                                id={'order.detail.requested_delivery_date'}
                                                            />
                                                        </th>
                                                        <td>
                                                            {!orderDetail.requested_delivery_date && '-'}
                                                            {orderDetail.requested_delivery_date && (
                                                                <FormattedDate
                                                                    value={orderDetail.requested_delivery_date}
                                                                    month={'long'}
                                                                    day={'numeric'}
                                                                    year={'numeric'}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id={'orders.ordered_quantity'} />
                                                    </th>
                                                    <td>
                                                        {typeof orderDetail.total_quantity === 'undefined' && '-'}
                                                        {typeof orderDetail.total_quantity !== 'undefined' &&
                                                            orderDetail.total_quantity}
                                                    </td>
                                                </tr>
                                                {orderDetail.state === OrderStates.Shipped && (
                                                    <tr>
                                                        <th>
                                                            <FormattedMessage id={'orders.shipped_quantity'} />
                                                        </th>
                                                        <td>
                                                            {typeof orderDetail.total_shipped_quantity ===
                                                                'undefined' && '-'}
                                                            {typeof orderDetail.total_shipped_quantity !==
                                                                'undefined' && orderDetail.total_shipped_quantity}
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id={'order.detail.comment'} />
                                                    </th>
                                                    <td>
                                                        {!orderDetail.comment && '-'}
                                                        {orderDetail.comment && <span>{orderDetail.comment}</span>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={10} className="col-volume-actions">
                                {customer?.show_container_volume && isNumber(orderDetail.total_volume) && (
                                    <Card className={classNames('member-card', 'volume')}>
                                        <Card.Header>
                                            <h3 className={'hv'}>
                                                <FormattedMessage id={'default.volume'} />
                                            </h3>
                                        </Card.Header>
                                        <Card.Body>
                                            <ContainerVolumes
                                                uniqId={orderDetail['@id']}
                                                totalVolume={orderDetail.total_volume}
                                                sm={12}
                                                lg={8}
                                            />
                                        </Card.Body>
                                    </Card>
                                )}
                                <Card className={classNames('member-card', 'actions')}>
                                    <Card.Header>
                                        <h3 className={'hv'}>
                                            <FormattedMessage id={'order.detail.actions'} />
                                        </h3>
                                    </Card.Header>
                                    <Card.Body>
                                        {allowedReOrder && orderDetail && (
                                            <ReOrderButton
                                                useCartType={isSalesman}
                                                order={orderDetail}
                                                label={
                                                    isSalesman && isOrderAssetType
                                                        ? formatMessage({ id: 'asset.detail.transform_to_order' })
                                                        : undefined
                                                }
                                            />
                                        )}
                                        {allowedExportInExcel && (
                                            <ExportButton
                                                block
                                                variant="primary"
                                                tooltip={null}
                                                icon={null}
                                                label={formatMessage({ id: 'order.detail.excel' })}
                                                order={orderDetail}
                                            />
                                        )}
                                        <hr className="actions-separator" />
                                        <DocumentButton
                                            block
                                            variant={'secondary'}
                                            icon={null}
                                            orderId={orderDetail.id}
                                            orderNumber={orderDetail.number}
                                            type={IOrderDocumentType.PurchaseOrderPdf}
                                            tooltip={null}
                                        />
                                        <DocumentButton
                                            block
                                            variant={'secondary'}
                                            icon={null}
                                            orderId={orderDetail.id}
                                            orderNumber={orderDetail.number}
                                            type={IOrderDocumentType.PurchaseOrderExcel}
                                            tooltip={null}
                                        />
                                        {orderDetail.state === OrderStates.Shipped && (
                                            <DocumentButton
                                                block
                                                variant={'secondary'}
                                                icon={null}
                                                orderId={orderDetail.id}
                                                orderNumber={orderDetail.number}
                                                type={IOrderDocumentType.DeliveryNoteExcel}
                                                tooltip={null}
                                            />
                                        )}
                                        {orderDetail.state === OrderStates.Shipped && customer?.allow_remainders && (
                                            <DocumentButton
                                                block
                                                variant={'secondary'}
                                                icon={null}
                                                orderId={orderDetail.id}
                                                orderNumber={orderDetail.number}
                                                type={IOrderDocumentType.DeliveryRemaindersExcel}
                                                tooltip={null}
                                            />
                                        )}

                                        {allowedDeclareAnomaly && orderDetail && (
                                            <OrderAnomalyButton
                                                onDone={handleOrderAnomalyModalDone}
                                                order={orderDetail}
                                            />
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={classNames('member-card', 'items')}>
                                    <Card.Header>
                                        <h3 className={'hv'}>
                                            <FormattedMessage id={'order.detail.items'} />
                                        </h3>
                                    </Card.Header>
                                    <Card.Body>
                                        {allowedExportPictures && (
                                            <div className="heading">
                                                {activeProductPicker ? (
                                                    <div className="picture-export-section">
                                                        <Row>
                                                            <Col xs={24} md={12} lg={14}>
                                                                <div className="help-message">
                                                                    <FlatIcon icon="info" />
                                                                    <FormattedMessage
                                                                        id="picture_export.help"
                                                                        values={{
                                                                            count: pictureAction.options.max,
                                                                            action_label: formatMessage({
                                                                                id: pictureAction.label,
                                                                            }),
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div
                                                                    className="picture-export-actions"
                                                                    onMouseEnter={handlePictureExportActionsEnter}
                                                                    onMouseLeave={handlePictureExportActionsLeave}
                                                                >
                                                                    <Overlay
                                                                        target={pictureExportActionRef.current}
                                                                        show={showPictureExportTooltip}
                                                                    >
                                                                        {(props) => (
                                                                            <Popover id="warnings-tooltip" {...props}>
                                                                                <Popover.Content>
                                                                                    {pictureExportWarnings.map(
                                                                                        (warning) => (
                                                                                            <p
                                                                                                className="invalid-feedback"
                                                                                                key={warning}
                                                                                            >
                                                                                                {warning}
                                                                                            </p>
                                                                                        )
                                                                                    )}
                                                                                </Popover.Content>
                                                                            </Popover>
                                                                        )}
                                                                    </Overlay>
                                                                    <PickerButton
                                                                        ref={pictureExportActionRef}
                                                                        disabled={
                                                                            selectedPickerProductIds.length <
                                                                                (pictureAction.options.min || 0) ||
                                                                            selectedPickerProductIds.length >
                                                                                (pictureAction.options.max || 9999)
                                                                        }
                                                                        onSelect={handlePickerActivationAsked}
                                                                        action={pictureAction}
                                                                        as={Button}
                                                                        variant="primary"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : (
                                                    <PickerButton
                                                        single
                                                        onSelect={handlePickerActivationAsked}
                                                        action={pictureAction}
                                                        as={Button}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        <Table className={'order-detail-table'} striped hover responsive>
                                            <thead>
                                                <tr>
                                                    {activeProductPicker && allowedExportPictures && (
                                                        <th className="col-product-checkbox">
                                                            <div className="pick-all">
                                                                <FormCheck
                                                                    custom
                                                                    type={'checkbox'}
                                                                    id={`picker-banner-select-all`}
                                                                >
                                                                    <FormCheck.Input
                                                                        type="checkbox"
                                                                        onChange={handlePickerSelectAllChange}
                                                                        value={1}
                                                                        checked={
                                                                            selectedPickerProductIds.length ===
                                                                            orderDetail?.items.length
                                                                        }
                                                                        ref={pickerSelectAllInputRef}
                                                                    />
                                                                    <FormCheck.Label
                                                                        htmlFor={`picker-banner-select-all`}
                                                                    />
                                                                </FormCheck>
                                                            </div>
                                                        </th>
                                                    )}
                                                    <th className="col-product" colSpan={2}>
                                                        {activeProductPicker && allowedExportPictures ? (
                                                            <FormCheck.Label htmlFor={`picker-banner-select-all`}>
                                                                <FormattedMessage id="default.select_unselect_all" />
                                                            </FormCheck.Label>
                                                        ) : (
                                                            <FormattedMessage
                                                                id={'products.plural'}
                                                                values={{ total: totalItems }}
                                                            />
                                                        )}
                                                    </th>
                                                    <th className={'order-detail-quantity'}>
                                                        <FormattedMessage id={'orders.ordered_quantity'} />
                                                    </th>
                                                    {orderDetail.state === OrderStates.Shipped && (
                                                        <th className={'order-detail-quantity'}>
                                                            <FormattedMessage id={'orders.shipped_quantity'} />
                                                        </th>
                                                    )}
                                                    {showPacking && (
                                                        <th>
                                                            <FormattedMessage id={'cart.packing'} />
                                                        </th>
                                                    )}
                                                    <th>
                                                        <FormattedMessage
                                                            id={
                                                                isNeedPriceIncludingTaxes
                                                                    ? 'cart.price_including_taxes'
                                                                    : 'cart.price_excluding_taxes'
                                                            }
                                                        />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage
                                                            id={
                                                                isNeedPriceIncludingTaxes
                                                                    ? 'cart.total_price_including_taxes'
                                                                    : 'cart.total_price_excluding_taxes'
                                                            }
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderDetail.items.map((item) => (
                                                    <tr key={`order_item_${item.id}`}>
                                                        {activeProductPicker && (
                                                            <td className="col-product-checkbox">
                                                                <div className="product-pick">
                                                                    <FormCheck
                                                                        custom
                                                                        type={'checkbox'}
                                                                        id={`check-product-${item.product.id}`}
                                                                    >
                                                                        <FormCheck.Input
                                                                            type="checkbox"
                                                                            onChange={handleProductPickerInputChange}
                                                                            value={item.product['@id']}
                                                                            checked={
                                                                                selectedPickerProductIds.indexOf(
                                                                                    item.product['@id']
                                                                                ) > -1
                                                                            }
                                                                        />
                                                                        <FormCheck.Label
                                                                            htmlFor={`check-product-${item.product.id}`}
                                                                        />
                                                                    </FormCheck>
                                                                </div>
                                                            </td>
                                                        )}
                                                        <td className="col-product">
                                                            <div className={'product-card'}>
                                                                <ProductMainPicture
                                                                    product={item.product}
                                                                    onImageClick={onProductImageClick}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="col-product">
                                                            <ProductHeading
                                                                product={item.product}
                                                                showEan
                                                                showReference
                                                                showReferenceLabel
                                                                showEanLabel
                                                                productNameAs={'p'}
                                                            />
                                                        </td>
                                                        <td>{item.quantity}</td>
                                                        {orderDetail.state === OrderStates.Shipped && (
                                                            <td>{item.shipped_quantity}</td>
                                                        )}
                                                        {showPacking && (
                                                            <td>
                                                                <ProductPacking
                                                                    packing={item.packing}
                                                                    showLabel={false}
                                                                    showIcon
                                                                />
                                                            </td>
                                                        )}
                                                        <td>
                                                            <Price price={item.unit_price} />
                                                        </td>
                                                        <td>
                                                            <Price price={item.total} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    {activeProductPicker && <td />}
                                                    <td className="col-product" colSpan={2}>
                                                        <FormattedMessage id={'cart.total'} />
                                                    </td>
                                                    <td>{orderDetail.total_quantity}</td>
                                                    {showPacking && (
                                                        <td>
                                                            <ProductPacking
                                                                packing={orderDetail.total_packing}
                                                                showLabel={false}
                                                                showIcon
                                                            />
                                                        </td>
                                                    )}
                                                    <td />
                                                    <td>
                                                        <Price price={orderDetail.total} />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {orderDetail.invoices && orderDetail.invoices.length > 0 && (
                            <Row>
                                <Col>
                                    <Card className={classNames('member-card', 'invoices')}>
                                        <Card.Header>
                                            <h3 className={'hv'}>
                                                <FormattedMessage id={'customer.invoices'} />
                                            </h3>
                                        </Card.Header>
                                        <Card.Body>
                                            <InvoiceList
                                                totalItems={orderDetail.invoices.length}
                                                pageSize={orderDetail.invoices.length}
                                                data={orderDetail.invoices}
                                                paginate={false}
                                                syncWithHistory={false}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </ReactPlaceholder>
            <PictureExportModal
                show={showPictureExportModal}
                identifiers={selectedPickerProductIds}
                onDone={handlePictureExportDone}
                onHide={handlePictureExportHide}
            />
        </>
    )
}

OrderDetail.defaultProps = {} as Partial<IProps>

export default OrderDetail
