import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProductsPage from './ProductsPage'
import PageLoader from '../components/Loader/PageLoader'
import { ProductsListMode } from '../services/api/service/products/types'
import { classificationFamilyTreeProcessAction } from '../store/classification/actions'
import { makeSelectClassificationFamilyTreeByMode } from '../store/classification/selectors'
import { Undefinable } from 'tsdef'
import { IFamilyTreeCollection } from '../services/api/service/classification/types'
import { IApplicationRootState } from '../store'
import { createStructuredSelector } from 'reselect'
import { makeSelectCustomer } from '../store/customers/selectors'
import { makeSelectAuthLoggedIn, makeSelectAuthMe } from '../store/auth/selectors'
import { makeSelectAppReload } from '../store/app/selectors'
import { ICustomer } from '../services/api/service/customers/types'
import { IMe } from '../services/api/service/me/types'
import { useIntl } from 'react-intl'
import { generatePath, Redirect } from 'react-router-dom'
import { getPath } from '../routes'
import { useCustomer } from '../utils/hook/useCustomer'

interface IProps {
    mode: ProductsListMode
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
    loggedIn: makeSelectAuthLoggedIn(),
    reloading: makeSelectAppReload(),
})

export default function ProductsLoaderPage({ mode }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { locale } = useIntl()
    const { isInternal: isInternalCustomer } = useCustomer()
    const { customer, loggedIn, me, reloading } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            loggedIn: boolean
            me?: IMe
            reloading: boolean
        }
    >(stateSelector)

    const selectFamilyTreeWithMode = useMemo(makeSelectClassificationFamilyTreeByMode, [])
    const familyTree: Undefinable<IFamilyTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<IFamilyTreeCollection>
    >((state) => {
        if (mode !== ProductsListMode.Category) {
            return selectFamilyTreeWithMode(state, mode)
        }
        return undefined
    })

    useEffect(() => {
        if (
            ((isInternalCustomer && [ProductsListMode.Default, ProductsListMode.Favorite].includes(mode)) ||
                (!isInternalCustomer && mode !== ProductsListMode.Category)) &&
            typeof familyTree === 'undefined'
        ) {
            // @ts-ignore
            dispatch(classificationFamilyTreeProcessAction(mode))
        }
    }, [dispatch, mode, familyTree, isInternalCustomer])

    if (
        isInternalCustomer &&
        ![ProductsListMode.Default, ProductsListMode.Category, ProductsListMode.Favorite].includes(mode)
    ) {
        return <Redirect to={generatePath(getPath('family', locale), { lang: locale })} />
    }

    if (typeof familyTree !== 'undefined') {
        return <ProductsPage mode={mode} />
    }

    return <PageLoader />
}
